import { ChainId } from '@pancakeswap/sdk';
import { Configuration } from './tomb-finance/config';
import { BankInfo } from './tomb-finance';

const configurations: { [env: string]: Configuration } = {
  // Don't update these addresses. that is not correct. X X X
  development: {
    chainId: ChainId.TESTNET,
    networkName: 'Fantom Opera Testnet',
    ftmscanUrl: 'https://testnet.ftmscan.com',
    defaultProvider: 'https://rpc.testnet.fantom.network/',
    deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WFTM: ['0xf1277d1ed8ad466beddf92ef448a132661956621', 18],
      FUSDT: ['0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', 18],
      'USDT-FTM-LP': ['0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b', 18],
      'TOMB-FTM-LP': ['0x13Fe199F19c8F719652985488F150762A5E9c3A8', 18],
      'TSHARE-FTM-LP': ['0x20bc90bB41228cb9ab412036F80CE4Ef0cAf1BD5', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:01:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },

  // addresses are here
  production: {
    chainId: ChainId.MAINNET,
    networkName: 'BSC Mainnet',
    ftmscanUrl: 'https://bscscan.com',
    defaultProvider: 'https://bscrpc.com',
    deployments: require('./tomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WFTM: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      FUSDT: ['0x55d398326f99059fF775485246999027B3197955', 18], // This is actually usdc on mainnet not fusdt
      'wFTM': ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      'USDT-FTM-LP': ['0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE', 18],
      'TOMB-FTM-LP': ['0xB168e03777bb117c6B86659eD06F8aAD495bD36e', 18],
      'TSHARE-FTM-LP': ['0xC0C3f9820a50F4c7F1DC477d3c983138Ed589c12', 18],
      TITANO: ['0x4e3cABD3AD77420FF9031d19899594041C420aeE', 18],
      HODL: ['0x0E9766dF73973abCfEDDE700497c57110ee5c301', 9],
      HODLX: ['0xAeBdbcc7d6821E3924EE5167b4138a43c19Cf681', 18]
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding TOMB
        - 2 = LP asset staking rewarding TSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */

  // TombFtmLPTShareRewardPool: {
  //   name: 'Earn TSHARE by TOMB-FTM LP',
  //   poolId: 1,
  //   sectionInUI: 2,
  //   contract: 'TombFtmLPTShareRewardPool',
  //   depositTokenName: 'TOMB-FTM-LP',
  //   earnTokenName: 'TSHARE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  // TshareFtmLPTShareRewardPool: {
  //   name: 'Earn TSHARE by TSHARE-FTM LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'TshareFtmLPTShareRewardPool',
  //   depositTokenName: 'TSHARE-FTM-LP',
  //   earnTokenName: 'TSHARE',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },
};

export default configurations['production'];
